define("discourse/plugins/discourse-post-badges-plugin/discourse/connectors/user-preferences-profile/user-featured-badges", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/service", "discourse-i18n", "select-kit/components/multi-select", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _service, _discourseI18n, _multiSelect, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserFeaturedBadges extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "featuredBadges", [_tracking.tracked], function () {
      return this.args.outletArgs.model.custom_fields.featured_badges.split(",").map(Number);
    }))();
    #featuredBadges = (() => (dt7948.i(this, "featuredBadges"), void 0))();
    updateFeaturedBadges(value) {
      this.featuredBadges = value;
      this.args.outletArgs.model.custom_fields.featured_badges = [...new Set(value)].join(",");
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateFeaturedBadges", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="control-group pref-featured-badges">
          <label class="control-label">{{i18n "user.featured_badges.label"}}</label>
          <div class="controls">
            <MultiSelect
              @value={{this.featuredBadges}}
              @onChange={{this.updateFeaturedBadges}}
              @content={{@outletArgs.model.badges}}
              @options={{hash maximum=3}}
              @none="user.featured_badges.none"
            />
          </div>
          <div class="instructions">
            {{i18n "user.featured_badges.description"}}
          </div>
        </div>
      
    */
    {
      "id": "Y4qH0NVw",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"control-group pref-featured-badges\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[32,0],[\"user.featured_badges.label\"],null]],[13],[1,\"\\n      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@value\",\"@onChange\",\"@content\",\"@options\",\"@none\"],[[30,0,[\"featuredBadges\"]],[30,0,[\"updateFeaturedBadges\"]],[30,1,[\"model\",\"badges\"]],[28,[32,2],null,[[\"maximum\"],[3]]],\"user.featured_badges.none\"]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"user.featured_badges.description\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-post-badges-plugin/discourse/connectors/user-preferences-profile/user-featured-badges.js",
      "scope": () => [_discourseI18n.i18n, _multiSelect.default, _helper.hash],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = UserFeaturedBadges;
});