define("discourse/plugins/discourse-post-badges-plugin/discourse/components/post-user-featured-badges", ["exports", "discourse/helpers/concat-class", "discourse/helpers/d-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _concatClass, _dIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PostUserFeaturedBadges = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#each @badges as |badge|}}
      <span
        class={{concatClass "poster-icon" badge.className badge.tlClassnames}}
        title={{badge.title}}
      >
        <a href={{badge.url}}>
          {{#if badge.image}}
            <img src={{badge.image}} alt={{badge.title}} />
          {{else}}
            {{icon badge.icon}}
          {{/if}}
        </a>
      </span>
    {{/each}}
  
  */
  {
    "id": "ALEnRdQ2",
    "block": "[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"    \"],[10,1],[15,0,[28,[32,0],[\"poster-icon\",[30,2,[\"className\"]],[30,2,[\"tlClassnames\"]]],null]],[15,\"title\",[30,2,[\"title\"]]],[12],[1,\"\\n      \"],[10,3],[15,6,[30,2,[\"url\"]]],[12],[1,\"\\n\"],[41,[30,2,[\"image\"]],[[[1,\"          \"],[10,\"img\"],[15,\"src\",[30,2,[\"image\"]]],[15,\"alt\",[30,2,[\"title\"]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[32,1],[[30,2,[\"icon\"]]],null]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null]],[\"@badges\",\"badge\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-post-badges-plugin/discourse/components/post-user-featured-badges.js",
    "scope": () => [_concatClass.default, _dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "post-user-featured-badges:PostUserFeaturedBadges"));
  var _default = _exports.default = PostUserFeaturedBadges;
});