define("discourse/plugins/discourse-post-badges-plugin/discourse/initializers/initialize-discourse-post-badges", ["exports", "@ember/runloop", "discourse/lib/helpers", "discourse/lib/plugin-api", "discourse/widgets/render-glimmer", "@ember/template-factory"], function (_exports, _runloop, _helpers, _pluginApi, _renderGlimmer, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const BADGE_CLASS = ["badge-type-gold", "badge-type-silver", "badge-type-bronze"];
  const TRUST_LEVEL_BADGE = ["basic", "member", "regular", "leader"];
  const USER_BADGE_PAGE = "user's badge page";
  function loadUserBadges(_ref2) {
    let {
      allBadges,
      username,
      linkDestination
    } = _ref2;
    let badgePage = "";
    const isUserBadgePage = linkDestination === USER_BADGE_PAGE;
    if (isUserBadgePage) {
      badgePage = `?username=${username}`;
    }
    return (0, _helpers.makeArray)(allBadges).map(badge => {
      return {
        icon: badge.icon.replace("fa-", ""),
        image: badge.image_url ? badge.image_url : badge.image,
        className: BADGE_CLASS[badge.badge_type_id - 1],
        tlClassnames: badge.id >= 1 && badge.id <= 4 ? TRUST_LEVEL_BADGE[badge.id - 1] : "",
        name: badge.slug,
        id: badge.id,
        badgeGroup: badge.badge_grouping_id,
        title: badge.description.replace(/<\/?[^>]+(>|$)/g, ""),
        url: `/badges/${badge.id}/${badge.slug}${badgePage}`
      };
    });
  }
  function addHighestTLClassname(widget, badges) {
    if (!widget || !badges) {
      return;
    }
    let trustLevel = "";
    let highestBadge = 0;
    badges.forEach(badge => {
      if (badge.badgeGroup === 4 && badge.id > highestBadge) {
        highestBadge = badge.id;
        trustLevel = `${TRUST_LEVEL_BADGE[highestBadge - 1]}-highest`;
      }
    });
    if (trustLevel) {
      widget._componentInfo.element.classList.add(trustLevel);
    }
  }
  var _default = _exports.default = {
    name: "discourse-post-badges-plugin",
    initialize(container) {
      (0, _pluginApi.withPluginApi)(api => {
        const siteSettings = container.lookup("service:site-settings");
        const isMobileView = container.lookup("service:site").mobileView;
        const location = isMobileView ? "before" : "after";
        let containerClassname = ["poster-icon-container"];
        if (siteSettings.post_badges_only_show_highest_trust_level) {
          containerClassname.push("show-highest");
        }
        (0, _renderGlimmer.registerWidgetShim)("featured-badges", `div.${containerClassname.join(".")}`, (0, _templateFactory.createTemplateFactory)(
        /*
          <PostUserFeaturedBadges @badges={{@data.badges}} @tagName="" />
        */
        {
          "id": "r/XKn3gM",
          "block": "[[[8,[39,0],null,[[\"@badges\",\"@tagName\"],[[30,1,[\"badges\"]],\"\"]],null]],[\"@data\"],false,[\"post-user-featured-badges\"]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-post-badges-plugin/discourse/initializers/initialize-discourse-post-badges.js",
          "isStrictMode": false
        }));
        api.includePostAttributes("user_badges");
        api.decorateWidget(`poster-name:${location}`, decorator => {
          const {
            user_badges: allBadges,
            username
          } = decorator.attrs;
          const linkDestination = siteSettings.post_badges_badge_link_destination;
          const badges = loadUserBadges({
            allBadges,
            username,
            linkDestination
          });
          const widget = decorator.attach("featured-badges", {
            badges
          });
          (0, _runloop.schedule)("afterRender", () => {
            addHighestTLClassname(widget, badges);
          });
          return widget;
        });
      });
    }
  };
});